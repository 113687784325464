import {
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useGlobalState } from 'App.state';
import { ConfirmDialog, SnackbarContext } from 'components';
import GoogleMapContainer from 'components/GoogleMapContainer/GoogleMapContainer';
import { OrderStatus, PaymentType } from 'enums';
import { DeliveryPackage, DeliveryPackageOrder } from 'interfaces';
import { deliveryPackageStatusToFriendly } from 'lib';
import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { isAndroid } from 'react-device-detect';
import { useParams, useHistory } from 'react-router-dom';
import { DeliveryPackageService } from 'services';

interface Params {
  id: string;
}

export const DeliveryOrders: React.FC = () => {
  const snackbar = React.useContext(SnackbarContext);
  const { id } = useParams<Params>();

  const [refresh, setRefresh] = React.useState(false);
  const [deliveryPackage, setDeliveryPackage] = React.useState<
    DeliveryPackage
  >();

  const [selectedOrder, setSelectedOrder] = React.useState<
    DeliveryPackageOrder
  >();
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showMainMap, setShowMainMap] = React.useState(true);

  const [, setDisableBaseButton] = useGlobalState('disableBaseButton');

  const [expandedOrders, setExpandedOrders] = React.useState<string[]>([]);
  const [expandedMaps, setExpandedMaps] = React.useState<string[]>([]);

  React.useEffect(() => {
    setLoading(true);
    DeliveryPackageService.getById(id).then((response) => {
      setLoading(false);
      setDeliveryPackage(response);
    });
  }, [refresh, id]);

  const history = useHistory();

  const importantText = deliveryPackage?.orders.some(
    (order) => order.payment?.paymentTypeId === PaymentType.CASH,
  )
    ? 'Important: make sure cash was collected from customer!'
    : '';

  return (
    <React.Fragment>
      <BreadcrumbsItem to={'/deliveries'}>Deliveries</BreadcrumbsItem>
      {loading && <LinearProgress />}
      {deliveryPackage ? (
        <React.Fragment>
          <Card>
            <CardContent
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                paddingBottom: '16px',
              }}
            >
              <div>
                <Typography variant="h6">
                  Delivery Package #{deliveryPackage.id}
                </Typography>
                <Typography variant="subtitle2">
                  {deliveryPackage.zone
                    ? `${deliveryPackage.zone.name} - `
                    : ''}
                  {deliveryPackage.deliveryPackageStatusId
                    ? deliveryPackageStatusToFriendly(
                        deliveryPackage.deliveryPackageStatusId,
                      )
                    : ''}
                </Typography>
              </div>
              <Button
                size="small"
                color="primary"
                onClick={() => setShowMainMap(!showMainMap)}
              >
                {showMainMap ? 'Hide Map' : 'Show Map'}
              </Button>
            </CardContent>
            {showMainMap && (
              <GoogleMapContainer
                zoom={13}
                coordinates={deliveryPackage.orders.map((o) => ({
                  lat: parseFloat(o.latitude),
                  lng: parseFloat(o.longitude),
                }))}
              />
            )}
          </Card>
        </React.Fragment>
      ) : null}
      <br />
      {deliveryPackage?.orders
        .sort((a, b) => a.locality.title.localeCompare(b.locality.title))
        .map((order) => (
          <React.Fragment key={order.id}>
            <Card>
              <CardContent
                style={{ justifyContent: 'space-between', display: 'flex' }}
              >
                <Typography variant="h6" component="h3">
                  Order No. {order.id}{' ('}{order.orderNumber ?? 'N/A'}{') '}
                  {order.localityName ? ` - ${order.localityName}` : ''}
                </Typography>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => toggleExpandedOrderMap(`${order.id}`)}
                >
                  {expandedMaps.includes(`${order.id}`)
                    ? 'Hide Map'
                    : 'Show Map'}
                </Button>
              </CardContent>
              {order.latitude && order.longitude ? (
                <Collapse
                  in={expandedMaps.includes(`${order.id}`)}
                  timeout="auto"
                  unmountOnExit
                >
                  <GoogleMapContainer
                    zoom={15}
                    coordinates={[
                      {
                        lat: parseFloat(order.latitude),
                        lng: parseFloat(order.longitude),
                      },
                    ]}
                  />
                </Collapse>
              ) : null}
              <CardContent>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    {order.customer?.houseName ? (
                    <Typography
                      variant="subtitle2"
                      color="textPrimary"
                      component="p"
                    >
                      House Name: {order.customer.houseName}
                    </Typography>
                    ) : (
                      ''
                    )}
                    <Typography
                      variant="subtitle2"
                      color="textPrimary"
                      component="p"
                    >
                      Address: {order.address},{' '}
                      {order?.locality?.title ?? order?.localityName ?? ''}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {Object.values(order.orderItems).length} menu items for
                      &euro;
                      {order.totalCostIncludingVat}
                    </Typography>
                    {order.payment &&
                    order.payment.paymentTypeId === PaymentType.CASH ? (
                      <Typography color="error" variant="subtitle2">
                        Cash Payment
                      </Typography>
                    ) : (
                      ''
                    )}
                    {order.customer?.phoneNumber ? (
                      <Typography color="primary" variant="subtitle2">
                        Phone number: <a style={{textDecorationColor: '#01aae9', color: '#01aae9'}} href={`tel:${order.customer?.phoneNumber}`}>{order.customer?.phoneNumber}</a>
                      </Typography>
                    ) : (
                      ''
                    )}
                  </div>
                  <IconButton
                    edge="end"
                    aria-label="expand"
                    onClick={() => {
                      toggleExpandedOrder(`${order.id}`);
                    }}
                  >
                    {!expandedOrders.includes(`${order.id}`) ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </IconButton>
                </div>
                <Collapse
                  in={expandedOrders.includes(`${order.id}`)}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {Object.values(order.orderItems).map((item) => (
                      <ListItem button key={item.id}>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle2">
                              x{item.quantity || 1} {item.menuItem.title}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  disabled={order.orderStatusId === OrderStatus.DELIVERED}
                  onClick={() => {
                    setConfirmOpen(true);
                    setSelectedOrder(order);
                  }}
                >
                  {order.orderStatusId === OrderStatus.DELIVERED
                    ? 'Delivered'
                    : 'Mark as Delivered'}
                </Button>
                <Button
                  size="small"
                  color="secondary"
                  component="a"
                  target="_blank"
                  href={getMapLink(order)}
                >
                  Start Navigation
                </Button>
              </CardActions>
            </Card>
            <br />
          </React.Fragment>
        )) ?? null}

      <ConfirmDialog
        title="Confirm"
        content="Are you sure you would like to mark this order as completed?"
        importantText={importantText}
        onConfirm={markOrderAsDelivered}
        dialogProps={{
          open: confirmOpen,
          onClose: (e) => {
            setConfirmOpen(false);
            setSelectedOrder(undefined);
          },
        }}
      />
    </React.Fragment>
  );

  function getMapLink(order: DeliveryPackageOrder) {
    if (!order.latitude || !order.longitude) {
      return `https://www.google.com/maps?saddr=My+Location&daddr=${order.address}`;
    }

    if (isAndroid) {
      return `google.navigation:q=${order.latitude},${order.longitude}`;
    }

    return `https://www.google.com/maps?saddr=My+Location&daddr=${order.latitude},${order.longitude}`;
  }

  async function markOrderAsDelivered() {
    if (!deliveryPackage || !selectedOrder) {
      return;
    }

    try {
      setLoading(true);
      const response = await DeliveryPackageService.markOrderAsDelivered({
        packageId: deliveryPackage.id,
        orderId: selectedOrder.id,
      });

      if (!response) {
        return;
      }

      setRefresh(!refresh);
      setDisableBaseButton(false);
      snackbar.open('Order has been marked as delivered');
      history.push('/deliveries');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }

  function toggleExpandedOrder(key: string) {
    let newExpandedOrders = expandedOrders.slice();

    if (newExpandedOrders.includes(key)) {
      newExpandedOrders = newExpandedOrders.filter((o) => key !== o);
    } else {
      newExpandedOrders.push(key);
    }

    setExpandedOrders(newExpandedOrders);
  }

  function toggleExpandedOrderMap(key: string) {
    let newExpandedMaps = expandedMaps.slice();

    if (newExpandedMaps.includes(key)) {
      newExpandedMaps = newExpandedMaps.filter((o) => key !== o);
    } else {
      newExpandedMaps.push(key);
    }

    setExpandedMaps(newExpandedMaps);
  }
};
