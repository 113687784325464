import currency from 'currency.js';

/**
 * Turns "myPascalCase" to "My Pascal Case"
 * @param input The string in pascal case
 */
export function pascalToWords(input: string): string {
  return (
    input
      // Split when an upper case letter follows a lower case letter
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      // Set first letter of input to upper case
      .replace(/^./, (c) => c.toUpperCase())
  );
}

export function underscoreCaseToWords(input: string): string {
  return capitalise(input.replace(/_/g, ' '));
}

export function pascalToUnderscoreCase(input: string): string {
  return input
    .replace(/\.?([A-Z])/g, (x, y) => {
      return '_' + y.toLowerCase();
    })
    .replace(/^_/, '');
}

/**
 * Capitalises strings
 * @param input the string to capitalise
 */
export function capitalise(input: string): string {
  const lowercase = input.toLowerCase();

  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce<T extends (...args: any) => any>(
  fn: T,
  wait: number,
  immediate?: boolean,
): T {
  let timeout: number | undefined;

  // @ts-ignore
  return (...args: Parameters<T>): ReturnType<T> => {
    const callNow = immediate && !timeout;

    window.clearTimeout(timeout);
    timeout = window.setTimeout(later, wait);

    if (callNow) {
      fn(...args);
    }

    function later() {
      timeout = undefined;

      if (!immediate) {
        fn(...args);
      }
    }
  };
}

/**
 * Formats a value as a monetary value
 */
export function toMonetary(input: currency.Any, options?: currency.Options) {
  // Hide decimal part if .00
  const precision = currency(input).cents() === 0 ? 0 : 2;

  return currency(input, {
    errorOnInvalid: true,
    formatWithSymbol: true,
    symbol: '\u20AC', // EUR
    precision,

    ...options,
  }).format();
}

export function isFunction(fn: any): fn is Function {
  return typeof fn === 'function';
}
