import { SvgIcon } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import * as React from 'react';
import { RouteProps } from 'react-router-dom';
import { Deliveries, DeliveryOrders } from '../../views';

interface SubPage {
  path: string;
  headerTitle: string;
  icon: typeof SvgIcon;
  searchTitle: string;
}

// Children not supported by AppLayout
export interface AppRouteProps extends Omit<RouteProps, 'children'> {
  path: string;
  headerTitle: string;
  sidebarName?: React.ReactNode;
  icon: typeof SvgIcon;
  subPages?: SubPage[];
}

export function getRoutes() {
  const routes: AppRouteProps[] = [
    {
      path: '/deliveries/:id([0-9]+)',
      headerTitle: 'Delivery Orders',
      icon: DashboardIcon,
      component: DeliveryOrders,
    },
    {
      path: '/deliveries',
      headerTitle: 'Deliveries',
      icon: DashboardIcon,
      component: Deliveries,
    },
  ];

  return routes;
}
