import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: '100%',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
