import { Button, makeStyles } from '@material-ui/core';
import { RouterNavLink } from 'components';
import * as React from 'react';
import { BreadcrumbsItemProps } from 'react-breadcrumbs-dynamic';

const useStyles = makeStyles((theme) => ({
  activeItem: {
    color: theme.palette.text.primary,
  },
}));

export const BreadcrumbItem: React.FC<BreadcrumbsItemProps> = (props) => {
  const classes = useStyles();

  return (
    <Button
      component={RouterNavLink}
      activeClassName={classes.activeItem}
      exact
      variant="text"
      size="small"
      color="secondary"
      {...props}
    />
  );
};
