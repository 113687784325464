import * as React from 'react';
import { AppTabs } from '../../components';
import { DeliveriesTab, CompletedDeliveriesTab } from './components';

interface Props {}

export enum DeliveryTabList {
  PACKAGES = 'packages',
  COMPLETED = 'completed',
}

export const Deliveries: React.FC<Props> = () => {
  const tabs = [
    { label: 'Active Packages', value: DeliveryTabList.PACKAGES },
    { label: 'Completed Packages', value: DeliveryTabList.COMPLETED },
  ];

  return (
    <AppTabs tabs={tabs}>
      {(activeTab) => {
        switch (activeTab) {
          case DeliveryTabList.PACKAGES:
            return <DeliveriesTab />;
          case DeliveryTabList.COMPLETED:
            return <CompletedDeliveriesTab />;
          default:
        }
      }}
    </AppTabs>
  );
};
