import {
  ClickAwayListener,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import classNames from 'classnames';
import { logout } from 'lib';
import { UserService } from 'services';
import * as React from 'react';
import { SnackbarContext } from 'components';
import { useGlobalState } from 'App.state';

interface Props extends Omit<ButtonProps, 'onClick'> {}

const useStyles = makeStyles((theme) => ({
  btnText: {
    marginLeft: theme.spacing(1),
  },
  popperClose: {
    pointerEvents: 'none',
  },
  popperResponsive: {
    [theme.breakpoints.down('md')]: {
      ...theme.mixins.popperResponsive,
    },
  },
  button: {
    backgroundColor: '#f3333e',
    padding: '10px',
    color: '#FFFFFF',
  },
  dropdown: {
    ...theme.mixins.dropdown,
  },
  dropdownItem: {
    ...theme.mixins.dropdownItem,
  },
}));

interface State {
  anchorEl?: HTMLElement;
}

export const AccountButton: React.FC<Props> = ({ ...rest }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const snackbar = React.useContext(SnackbarContext);
  const open = Boolean(anchorEl);
  const [disableBaseButton, setDisableBaseButton] = useGlobalState('disableBaseButton');
  return (
    <React.Fragment>
      <Button disabled={Boolean(disableBaseButton)} onClick={returnToBase} {...rest}>
        <span className={classes.button}>{'Returned to base'}</span>
      </Button>
      
      <Button onClick={handleClick} {...rest}>
        <span className={classes.btnText}>{'Account'}</span>
      </Button>

      <Popper
        placement="bottom-start"
        open={open}
        className={classNames(classes.popperResponsive, {
          [classes.popperClose]: !open,
        })}
        anchorEl={anchorEl}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClick}>
            <Grow {...TransitionProps}>
              <Paper className={classes.dropdown}>
                <MenuList role="menu">
                  <MenuItem onClick={logout} className={classes.dropdownItem}>
                    Log out
                  </MenuItem>
                </MenuList>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </React.Fragment>
  );

  async function returnToBase() {
    const response = await UserService.driverAtStation();
    if (response.error) {
      return snackbar.error(response.message);
    }
    setDisableBaseButton(true);
    return snackbar.success('Returned to station successfully');
  }

  function handleClick({
    currentTarget,
  }: React.SyntheticEvent<HTMLElement | {}>) {
    setAnchorEl(
      anchorEl || !(currentTarget instanceof Element)
        ? undefined
        : currentTarget,
    );
  }
};
