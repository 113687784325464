import * as React from 'react';

export function isCheckboxChangeEvent(
  value: any,
): value is React.ChangeEvent<HTMLInputElement> {
  return isChangeEvent(value) && value.currentTarget.hasOwnProperty('checked');
}

export function isChangeEvent(
  value: any,
): value is React.ChangeEvent<
  HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
> {
  return typeof value === 'object' && value.hasOwnProperty('target');
}
