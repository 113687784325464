import { ApiClient } from 'ApiClient';

class DriverCashFlowService {
  public async saveDriverCashFlow(formData: any) {
    return ApiClient.post('driver-cash-flows', formData);
  }

  public getCashflowSum({
    dateFrom,
    dateTo,
  }: {
    dateFrom: string;
    dateTo: string;
  }, {
    userId
  }: {
    userId: number;
  }): Promise<{sum:string}> {
    return ApiClient.get('driver-cash-flows', {
      dateFrom,
      dateTo,
      userId
    });
  }
}

const singleton = new DriverCashFlowService();

export { singleton as DriverCashFlowService };
