import { Config } from 'config';
import {
  GoogleApiWrapper,
  IProvidedProps,
  Map,
  Marker,
} from 'google-maps-react';
import * as React from 'react';
import { useStyles } from './styles';

export interface LatLong {
  lat: number;
  lng: number;
}

interface Props extends IProvidedProps {
  coordinates: LatLong[];
  zoom: number;
}

export const GoogleMapContainer: React.FC<Props> = ({
  coordinates,
  zoom,
  google,
}) => {
  const classes = useStyles();

  const bounds = new google.maps.LatLngBounds();

  coordinates.forEach((coords) => bounds.extend(coords));

  return (
    <div className={classes.mapContainer}>
      <Map
        google={google}
        zoom={zoom}
        initialCenter={{
          lat: bounds.getCenter().lat(),
          lng: bounds.getCenter().lng(),
        }}
        clickableIcons={false}
        disableDefaultUI
        style={{ height: 200, position: 'relative' }}
        center={{
          lat: bounds.getCenter().lat(),
          lng: bounds.getCenter().lng(),
        }}
        bounds={bounds}
      >
        {coordinates.map((coords, index) => (
          <Marker
            key={index}
            position={coords}
            onClick={() => {
              window.open(
                `https://www.google.com/maps?saddr=My+Location&daddr=${coords.lat},${coords.lng}`,
                '_blank',
              );
            }}
          />
        ))}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({ apiKey: Config.GOOGLE_API_KEY })(
  GoogleMapContainer,
);
