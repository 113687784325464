import Cookies from 'js-cookie';
import { FirebaseService } from 'services';
import { setGlobalState } from '../App.state';
import { ApiClient } from 'ApiClient';

export function logout() {
  setGlobalState('authUser', null);

  ApiClient.post(
    'users/logout',
    {},
    true,
  );

  Cookies.remove('authUser', { path: '/' });

  FirebaseService.getFirebase()
    .auth()
    .signOut();
}
