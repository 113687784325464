import { Config } from 'config';
import * as firebase from 'firebase';
import 'firebase/database';

class FirebaseService {
  config = {
    apiKey: Config.FIREBASE.API_KEY,
    databaseURL: Config.FIREBASE.DATABASE_URL,
    projectId: Config.FIREBASE.PROJECT_ID,
    authDomain: Config.FIREBASE.AUTH_DOMAIN,
    storageBucket: Config.FIREBASE.STORAGE_BUCKET,
    messagingSenderId: Config.FIREBASE.MESSAGING_SENDER_ID,
    appId: Config.FIREBASE.APP_ID,
    measurementId: Config.FIREBASE.MEASURMENT_ID,
  };

  constructor() {
    firebase.initializeApp(this.config);
  }
  
  public getDatabase() {
    return firebase.database();
  }

  public getFirebase() {
    return firebase;
  }

  public getConfig() {
    return this.config;
  }
}

const singleton = new FirebaseService();

export { singleton as FirebaseService };
