import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AlarmIcon from '@material-ui/icons/Alarm';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { DeliveryPackageStatus, PaymentType } from 'enums';
import { DeliveryPackage, Setting } from 'interfaces';
import { deliveryPackageStatusToFriendly } from 'lib';
import * as React from 'react';
import { useStyles } from '../DeliveriesTab/styles';
import { RouterLink } from 'components';
import moment from 'moment'

interface Props {
  readonly?: boolean;
  deliveryPackage: DeliveryPackage;
  startDelivery?: (deliveryPackage: DeliveryPackage) => void;
  setting?: Setting;
}

export const DeliveryPackageCard: React.FC<Props> = ({
  readonly = false,
  deliveryPackage,
  startDelivery,
  setting
}) => {
  const classes = useStyles();

  const [expandedOrders, setExpandedOrders] = React.useState<string[]>([]);
  const [expandedPackages, setExpandedPackages] = React.useState<string[]>([]);

  return (
    <Card className={classes.card}>
      <CardHeader
        title={
          <Typography variant="h6">
            Delivery Package #{deliveryPackage.id}
          </Typography>
        }
        subheader={
          <Typography variant="subtitle2">
            {deliveryPackage.zoneName ? `${deliveryPackage.zoneName} - ` : ''}
            {deliveryPackage.deliveryPackageStatusId
              ? deliveryPackageStatusToFriendly(
                  deliveryPackage.deliveryPackageStatusId,
                )
              : ''}
          </Typography>
        }
        action={
          <IconButton
            edge="end"
            aria-label="expand"
            style={{ color: '#fff', marginRight: 8, marginTop: 8 }}
            onClick={() => {
              toggleExpandedPackages(`${deliveryPackage.id}`);
            }}
          >
            {!expandedPackages.includes(deliveryPackage.id.toString()) ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </IconButton>
        }
        style={{
          background: getColorBasedOnStatus(
            deliveryPackage.deliveryPackageStatusId,
          ),
          color: '#fff',
        }}
      />
      <Collapse
        in={expandedPackages.includes(`${deliveryPackage.id}`)}
        timeout="auto"
        unmountOnExit
      >
        <CardContent style={{paddingLeft: 0, paddingRight: 0}}>
          <List dense>
            {deliveryPackage.orders ? (
              Object.values(deliveryPackage.orders).map((order) => (
                <React.Fragment key={order.id}>
                  <ListItem button>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle2">
                          Order No. {order.id}{' ('}{order.orderNumber ?? 'N/A'}{') '}
                          {order.localityName ? ` - ${order.localityName}` : ''}
                        </Typography>
                      }
                      secondary={
                        <div>
                          <Typography variant="body2">
                            {Object.values(order.orderItems).length} menu items
                            for &euro;{order.totalCostIncludingVat}
                          </Typography>
                          {order.paymentType &&
                          order.paymentType === PaymentType.CASH ? (
                            <Typography color="error" variant="subtitle2">
                              Cash Payment
                            </Typography>
                          ) : (
                            ''
                          )}
                        </div>
                      }
                    />
                    {order.orderCreated ? (
                    <ListItemText
                      primary={
                        <Typography style={{ display: 'inline-block'}} variant="subtitle2">
                          <Tooltip  title="Estimated Delivery at">
                            <IconButton aria-label="Estimated Delivery at">
                              <AlarmIcon />
                            </IconButton>
                          </Tooltip>
                          <div style={{display: 'flex', justifyContent:'center', alignItems:'center'}}> {moment(order.orderCreated).add(parseInt(setting?.value ??  '0'), 'minutes').format('HH:mm')} </div>
                        </Typography>
                      }
                    />
                    ) : (
                      ''
                    )}
                    {order.numberOfBags ? (
                    <ListItemText
                      primary={
                        <Typography style={{ display: 'inline-block'}} variant="subtitle2">
                          <Tooltip  title="Number of bags">
                            <IconButton aria-label="Number of bags">
                              <LocalMallIcon  />
                            </IconButton>
                          </Tooltip>
                          <div style={{display: 'flex', justifyContent:'center', alignItems:'center'}}> {order.numberOfBags} </div>
                        </Typography>
                      }
                    />
                    ) : (
                      ''
                    )}
                    {order.hasDrinks ? (
                    <ListItemText
                      primary={
                        <Typography variant="subtitle2">
                          <Tooltip title="Has Drinks">
                            <IconButton style={{ color: '#f3333e'}} aria-label="Has Drinks">
                              <FastfoodIcon />
                            </IconButton>
                          </Tooltip>
                      <div style={{height : 20}}></div>
                        </Typography>
                      }
                    />
                    ) : (
                      ''
                    )}
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="expand"
                        onClick={() => {
                          toggleExpandedOrder(
                            `${deliveryPackage.id}-${order.id}`,
                          );
                        }}
                      >
                        {!expandedOrders.includes(
                          `${deliveryPackage.id}-${order.id}`,
                        ) ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Collapse
                    in={expandedOrders.includes(
                      `${deliveryPackage.id}-${order.id}`,
                    )}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {Object.values(order.orderItems).map((item) => (
                        <ListItem
                          button
                          className={classes.nested}
                          key={item.id}
                        >
                          <ListItemText
                            primary={
                              <Typography variant="subtitle2">
                                x{item.quantity || 1} {item.menuItem.title}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              ))
            ) : (
              <Typography align="center" variant="subtitle2">
                No orders yet
              </Typography>
            )}
          </List>
        </CardContent>
      </Collapse>
      <Divider />
      <CardActions disableSpacing style={{ justifyContent: 'space-around' }}>
        {[
          DeliveryPackageStatus.OUT_FOR_DELIVERY,
          DeliveryPackageStatus.DELIVERED,
        ].includes(deliveryPackage.deliveryPackageStatusId) && (
          <IconButton
            aria-label="Start"
            component={RouterLink}
            to={`/deliveries/${deliveryPackage.id}`}
          >
            <Tooltip title="View Delivery">
              <React.Fragment>
                <VisibilityIcon />
                <Typography variant="subtitle2" style={{ paddingLeft: 4 }}>
                  View Orders
                </Typography>
              </React.Fragment>
            </Tooltip>
          </IconButton>
        )}
        {!readonly &&
          [
            DeliveryPackageStatus.ASSIGNED,
            DeliveryPackageStatus.WAITING_FOR_PICKUP,
          ].includes(deliveryPackage.deliveryPackageStatusId) && (
            <IconButton
              aria-label="Start"
              onClick={() => startDelivery && startDelivery(deliveryPackage)}
            >
              <Tooltip title="Start">
                <React.Fragment>
                  <PlayArrowIcon />
                  <Typography variant="subtitle2" style={{ paddingLeft: 4 }}>
                    Start Delivery
                  </Typography>
                </React.Fragment>
              </Tooltip>
            </IconButton>
          )}
        {deliveryPackage.assigneeName ? (
          <Typography variant="subtitle2" align="right">
            {deliveryPackage.assigneeName}
            {deliveryPackage.vehicleType
              ? ` (${deliveryPackage.vehicleType})`
              : ''}
          </Typography>
        ) : deliveryPackage?.userVehicleType ? (
          <Typography variant="subtitle2" align="right">
            {deliveryPackage.userVehicleType.user.name}{' '}
            {deliveryPackage.userVehicleType.user.surname}
            {deliveryPackage.userVehicleType.vehicleType
              ? ` (${deliveryPackage.userVehicleType.vehicleType.name})`
              : ''}
          </Typography>
        ) : (
          <Typography variant="subtitle2" align="right">
            Unassigned
          </Typography>
        )}
      </CardActions>
    </Card>
  );

  function getColorBasedOnStatus(statusId: number) {
    switch (statusId) {
      case DeliveryPackageStatus.ASSIGNED:
        return '#dab969';
      case DeliveryPackageStatus.OUT_FOR_DELIVERY:
        return '#01aae9';
      case DeliveryPackageStatus.DELIVERED:
        return '#4BBB67';
    }

    return '#f3333e';
  }

  function toggleExpandedOrder(key: string) {
    let newExpandedOrders = expandedOrders.slice();

    if (newExpandedOrders.includes(key)) {
      newExpandedOrders = newExpandedOrders.filter((o) => key !== o);
    } else {
      newExpandedOrders.push(key);
    }

    setExpandedOrders(newExpandedOrders);
  }

  function toggleExpandedPackages(key: string) {
    let newExpandedPackages = expandedPackages.slice();

    if (newExpandedPackages.includes(key)) {
      newExpandedPackages = newExpandedPackages.filter((o) => key !== o);
    } else {
      newExpandedPackages.push(key);
    }

    setExpandedPackages(newExpandedPackages);
  }
};
