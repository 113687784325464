import { ApiClient } from 'ApiClient';
import { User } from 'interfaces';

class UserService {
  public forgotPassword(data: any) {
    return ApiClient.post('users/forgot-password', data);
  }

  public resetPassword(data: any) {
    return ApiClient.put('users/reset-password', data);
  }

  public getAllOnDuty(): Promise<User[]> {
    return ApiClient.get<User[]>('users/on-duty');
  }

  public getMe(): Promise<User> {
    return ApiClient.get<User>('users/with-token');
  }

  public driverAtStation() {
    return ApiClient.get(`users/arrived-at-station`);
  }

  public selectVehicle(data: any) {
    return ApiClient.post('users/select-vehicle', data);
  }
}

const singleton = new UserService();

export { singleton as UserService };
