import { DialogProps, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FormDialog, SnackbarContext } from 'components';
import { usePrevious } from 'hooks';
import { User, Vehicle } from 'interfaces';
import * as React from 'react';
import { UserService } from 'services';

interface Props {
  dialogProps: DialogProps;
  me: User;
}

interface Form {
  vehicleId?: number;
}

export const ChooseVehicleDialog: React.FC<Props> = ({ dialogProps, me }) => {
  const snackbar = React.useContext(SnackbarContext);

  const [loading, setLoading] = React.useState(false);

  const getForm = React.useCallback(
    function getForm(): Form {
      return {
        vehicleId: me.vehicleId ?? undefined,
      };
    },
    [me],
  );

  const [form, setForm] = React.useState(getForm());

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm());
    }
  }, [getForm, justOpened]);

  return (
    <FormDialog
      title="Select Vehicle"
      submitText="Select"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item xs={12}>
          <Autocomplete
            value={
              getVehicleTypes().find((vt) => vt.id === form.vehicleId) || null
            }
            options={getVehicleTypes()}
            getOptionLabel={(option: Vehicle) =>
              `${option.name} - ${option.numberPlate}` ?? ''
            }
            onChange={(e: React.ChangeEvent<{}>, vehicle: Vehicle | null) =>
              handleAutocompleteChange(vehicle?.id ?? null, 'vehicleId')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="vehicleId"
                variant="standard"
                label="Vehicle"
              />
            )}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function getVehicleTypes() {
    return me.userVehicleTypes.reduce<Vehicle[]>((prev, curr) => {
      return [...prev, ...curr.vehicleType.vehicles];
    }, []);
  }

  function handleAutocompleteChange(value: any, name: string) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      if (!form.vehicleId) {
        throw new Error('Please select a vehicle');
      }

      const response = await UserService.selectVehicle({
        vehicleId: form.vehicleId,
      });

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      snackbar.open('Vehicle selection has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
