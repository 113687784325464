import { Button, Grid, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  ChooseVehicleDialog,
  DelayedLinearProgress,
  EmptyView,
  SnackbarContext,
} from 'components';
import { Settings } from 'enums';
import { DeliveryPackage, Setting, User, Vehicle } from 'interfaces';
import * as React from 'react';
import { useListVals } from 'react-firebase-hooks/database';
import { DeliveryPackageService, SettingService, UserService } from 'services';
import { FirebaseService } from 'services/FirebaseService';
import { DeliveryPackageCard } from '../DeliveryPackageCard';

export const Deliveries: React.FC = () => {
  const snackbar = React.useContext(SnackbarContext);
  const [setting, setSetting] = React.useState<Setting>();

  const [deliveryPackages, loading] = useListVals<DeliveryPackage>(
    FirebaseService.getDatabase().ref('deliverypackages'),
    { keyField: 'id' },
  );

  const [me, setMe] = React.useState<User>();

  const [vehicleSelectionOpen, setVehicleSelectionOpen] = React.useState(false);

  React.useEffect(() => {
    getMe();
    getSettingDeliveryTime();
  }, []);

  const selectedVehicle =
    me && me.vehicleId
      ? me.userVehicleTypes.reduce<Partial<Vehicle>>((prev, curr) => {
          const vehicle = curr.vehicleType.vehicles.find(
            (v) => v.id === me.vehicleId,
          );

          if (vehicle) {
            return vehicle;
          }

          return prev;
        }, {})
      : undefined;

  return (
    <React.Fragment>
      {loading && <DelayedLinearProgress />}

      {selectedVehicle?.name && (
        <Paper style={{ marginBottom: 16 }}>
          <Alert
            severity="info"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => setVehicleSelectionOpen(true)}
              >
                Change
              </Button>
            }
          >
            Vehicle selected is{' '}
            <strong>
              {selectedVehicle.name} - {selectedVehicle.numberPlate}
            </strong>
          </Alert>
        </Paper>
      )}

      <Grid container>
        {deliveryPackages?.length ? (
          deliveryPackages
            .filter((dp) => dp.assigneeId === me?.id)
            .map((deliveryPackage) => {
              return (
                <Grid item xs={12} sm={6} md={4} key={deliveryPackage.id}>
                  <DeliveryPackageCard
                    deliveryPackage={deliveryPackage}
                    startDelivery={startDelivery}
                    setting={setting}
                  />
                </Grid>
              );
            })
        ) : (
          <Grid item xs={12}>
            <Paper>
              <EmptyView />
            </Paper>
          </Grid>
        )}
      </Grid>

      {me && (
        <ChooseVehicleDialog
          me={me}
          dialogProps={{
            open: vehicleSelectionOpen,
            onClose: (e) => {
              setVehicleSelectionOpen(false);
              getMe();
            },
            fullWidth: true,
            maxWidth: 'sm',
          }}
        />
      )}
    </React.Fragment>
  );

  function getMe() {
    UserService.getMe().then((response) => {
      setMe(response);
      setVehicleSelectionOpen(!response.vehicleId ? true : false);
    });
  }

  async function startDelivery(deliveryPackage: DeliveryPackage) {
    if (!me) {
      return;
    }

    if (!me.vehicleId) {
      setVehicleSelectionOpen(true);
      return;
    }

    const selectedVehicleType = me.userVehicleTypes.find((type) =>
      type.vehicleType.vehicles.map((v) => v.id).includes(me.vehicleId),
    );

    if (!selectedVehicleType) {
      setVehicleSelectionOpen(true);
      return;
    }

    try {
      const response = await DeliveryPackageService.startPackage({
        id: deliveryPackage.id,
        data: {
          userId: me.id,
          vehicleTypeId: selectedVehicleType.vehicleTypeId,
        },
      });

      if (!response) {
        return;
      }

      snackbar.open('Delivery package status has been changed.');
    } catch (error) {
      snackbar.error(error);
    }
  }

  async function getSettingDeliveryTime() {
    const response = await SettingService.view(Settings.DELIVERY_TIME);
    setSetting(response);
  }
};
