import { ApiClient } from 'ApiClient';
import { Setting } from 'interfaces';

class SettingService {
  public view(id: number) {
    return ApiClient.get<Setting[]>(`settings/${id}`);
  }
}

const singleton = new SettingService();

export { singleton as SettingService };
