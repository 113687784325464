import { Box, Card, CardContent, makeStyles } from '@material-ui/core';
import { DeliveryPackage } from 'interfaces';
import * as React from 'react';
import { PaymentType } from 'enums';

interface Report {
  numberOfPackages: number;
  numberOfOrders: number;
  cashToCollect: number;
}

interface Props {
  deliveryPackages: DeliveryPackage[];
  cashFlowSum: number;
}

const useStyles = makeStyles(({ palette }) => ({
  card: {
    borderRadius: 4,
    textAlign: 'center',
    overflow: 'auto',
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    marginTop: 8,
    marginBottom: 0,
  },
  statLabel: {
    fontSize: 12,
    color: palette.grey[500],
    fontWeight: 500,
    margin: 0,
  },
  statValue: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 4,
    letterSpacing: '1px',
  },
}));

export const Summary: React.FC<Props> = ({ deliveryPackages, cashFlowSum }) => {
  const styles = useStyles();

  const {
    numberOfPackages,
    numberOfOrders,
    cashToCollect,
  } = deliveryPackages?.reduce<Report>(
    (prev, curr) => ({
      numberOfPackages: prev.numberOfPackages + 1,
      numberOfOrders: prev.numberOfOrders + curr.orders.length,
      cashToCollect:
        prev.cashToCollect +
        curr.orders
          .filter(
            (order) =>
              order.payment &&
              order.payment?.paymentTypeId === PaymentType.CASH,
          )
          .reduce(
            (prevTotal, order) =>
              prevTotal + parseFloat(order.totalCostIncludingVat.toString()),
            0,
          ),
    }),
    {
      numberOfPackages: 0,
      numberOfOrders: 0,
      cashToCollect: 0,
    },
  ) ?? {
    numberOfPackages: 0,
    numberOfOrders: 0,
    cashToCollect: 0,
  };

  return (
    <Card className={styles.card}>
      <CardContent style={{ paddingBottom: 0 }}>
        <h3 className={styles.heading}>Summary</h3>
      </CardContent>
      <Box display={'flex'}>
        <Box p={2} flex={'auto'}>
          <p className={styles.statLabel}>Packages</p>
          <p className={styles.statValue}>{numberOfPackages}</p>
        </Box>
        <Box p={2} flex={'auto'}>
          <p className={styles.statLabel}>Orders</p>
          <p className={styles.statValue}>{numberOfOrders}</p>
        </Box>
        <Box p={2} flex={'auto'}>
          <p className={styles.statLabel}>Cash Flow</p>
          <p className={styles.statValue}>&euro;{cashFlowSum.toFixed(2)}</p>
        </Box>
        <Box p={2} flex={'auto'}>
          <p className={styles.statLabel}>Cash to collect</p>
          <p className={styles.statValue}>
            &euro;{(cashToCollect + cashFlowSum).toFixed(2)}
          </p>
        </Box>
      </Box>
    </Card>
  );
};
