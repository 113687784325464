import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Breadcrumbs as DynamicBreadcrumbs } from 'react-breadcrumbs-dynamic';
import { BreadcrumbItem } from './BreadcrumbItem';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    marginBottom: theme.spacing(1),
  },
}));

export const Breadcrumbs: React.FC = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.breadcrumbs}>
      <DynamicBreadcrumbs separator=" / " item={BreadcrumbItem} />
    </div>
  );
};
