import { Grid, Paper, Typography } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DatePicker, DelayedLinearProgress, EmptyView, SnackbarContext} from 'components';
import { DateFormat } from 'config';
import { format } from 'date-fns';
import { DeliveryPackage } from 'interfaces';
import * as React from 'react';
import { DeliveryPackageService, DriverCashFlowService, UserService } from 'services';
import { DeliveryPackageCard } from '../DeliveryPackageCard';
import { Summary } from './Summary';
import { usePrevious } from 'hooks';

export const CompletedDeliveries: React.FC = () => {
  const [deliveryPackages, setDeliveryPackages] = React.useState<
    DeliveryPackage[]
  >([]);
  const [loading, setLoading] = React.useState(false);
  const [filters, setFilters] = React.useState({
    dateFrom: format(new Date(), DateFormat.INPUT),
    dateTo: format(new Date(), DateFormat.INPUT),
  });
  const snackbar = React.useContext(SnackbarContext);
  const prevFilters = usePrevious(filters);
  const [cashFlowSum, setCashFlowSum] = React.useState<number>(0.0);

  const getCashFlow = React.useCallback(
    async function getCashFlow(filters) {
      try {
        const response = await UserService.getMe();

        if (!response) {
          return;
        }

        DriverCashFlowService.getCashflowSum(filters, {userId: response.id}).then(
          (response) => {
            if (response.sum === null) {
              setCashFlowSum(0);
            } else {
              setCashFlowSum(parseFloat(response.sum));
            }
          },
        );
      } catch (error) {
        snackbar.error(error);
      }
    },
    [snackbar],
  );

  React.useEffect(() => {
    setCashFlowSum(0);
    if (prevFilters !== filters) {
      getCashFlow(filters);
      setLoading(true);
      DeliveryPackageService.getAllCompletedPackages(filters).then(
        (response) => {
          if (response) {
            setDeliveryPackages(response);
          }
          setLoading(false);
        },
      );
    }
  }, [filters, prevFilters, getCashFlow]);

  return (
    <React.Fragment>
      {loading && <DelayedLinearProgress />}

      <Paper style={{ padding: 16 }}>
        <Grid container direction="row">
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              From
            </Typography>

            <DatePicker
              fullWidth
              value={filters.dateFrom || ''}
              onChange={(e: MaterialUiPickersDate) =>
                onDateFilterChange(e, 'dateFrom')
              }
            />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              To
            </Typography>

            <DatePicker
              fullWidth
              value={filters.dateTo || ''}
              onChange={(e: MaterialUiPickersDate) =>
                onDateFilterChange(e, 'dateTo')
              }
            />
          </Grid>
        </Grid>
      </Paper>

      <br />

      <Summary
        deliveryPackages={deliveryPackages}
        cashFlowSum={cashFlowSum} 
      />

      <br />

      <Grid container>
        {deliveryPackages.length ? (
          deliveryPackages.map((deliveryPackage) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={deliveryPackage.id}>
                <DeliveryPackageCard
                  deliveryPackage={deliveryPackage}
                  readonly
                />
              </Grid>
            );
          })
        ) : (
          <Grid item xs={12}>
            <Paper>
              <EmptyView />
            </Paper>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );

  function onDateFilterChange(
    newVal: MaterialUiPickersDate,
    field: 'dateFrom' | 'dateTo',
  ) {
    setFilters((f) => ({
      ...f,
      [field]: newVal && format(newVal, DateFormat.INPUT),
    }));
  }
};
