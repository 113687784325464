import {
  KeyboardDatePicker,
  DatePicker as MaterialDatePicker,
  DatePickerProps,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import * as React from 'react';
import { DateFormat } from '../../config';
import { isValid } from 'date-fns';

type DatePickerComponentProps = KeyboardDatePickerProps | DatePickerProps;

export const DatePicker: React.FC<DatePickerComponentProps & {
  disableKeyboardInput?: boolean;
}> = ({
  value,
  format = DateFormat.SHORT,
  onChange,
  disableKeyboardInput,
  ...rest
}) => {
  const [error, setError] = React.useState(false);
  const [shouldShowError, setShouldShowError] = React.useState(false);

  return disableKeyboardInput ? (
    <MaterialDatePicker
      format={format}
      value={value || null}
      placeholder={format && format.toLowerCase()}
      autoOk
      onChange={onChange}
      {...rest}
    />
  ) : (
    <KeyboardDatePicker
      format={format}
      error={shouldShowError && error}
      value={value || null}
      placeholder={format && format.toLowerCase()}
      helperText={shouldShowError && error ? `Invalid date` : ''}
      FormHelperTextProps={{ error: true }}
      onBlur={() => setShouldShowError(true)}
      onFocus={() => setShouldShowError(false)}
      onChange={(date, value) => {
        if (!date || isValid(date)) {
          setError(false);
          onChange(date, value);
        } else {
          setError(true);
        }
      }}
      autoOk
      {...rest}
    />
  );
};
