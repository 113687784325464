import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { RouterLink, SnackbarContext } from 'components';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { UserService } from 'services';

export const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(0, 0.5, 2, 0.5),
  },
  cardActions: {
    justifyContent: 'space-between',
  },
  cardHeader: {
    background: 'none',
    textAlign: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: 0,
  },
  cardHeaderTitle: {
    color: theme.palette.primary.main,
  },
}));

export const ForgotPassword: React.FC = () => {
  const snackbar = React.useContext(SnackbarContext);
  const classes = useStyles();

  const [emailAddress, setEmailAddress] = React.useState('');
  const [redirect, setRedirect] = React.useState(false);

  if (redirect) {
    return <Redirect to="/login" />;
  }

  return (
    <Card className={classes.card}>
      <form onSubmit={submit}>
        <CardHeader
          className={classes.cardHeader}
          title="Forgot Password"
          subheader="Use your email address to reset your password"
          classes={{ title: classes.cardHeaderTitle }}
        />

        <CardContent>
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            name="emailAddress"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            type="email"
            required
          />
        </CardContent>

        <CardActions className={classes.cardActions}>
          <Button
            type="button"
            size="small"
            color="default"
            component={RouterLink}
            to="/login"
          >
            Back to Login
          </Button>

          <Button
            type="submit"
            size="small"
            color="primary"
            variant="contained"
          >
            Reset My Password
          </Button>
        </CardActions>
      </form>
    </Card>
  );

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      const response = await UserService.forgotPassword({ emailAddress });

      if (!response) {
        return;
      }

      snackbar.open('Email has been sent');
      setRedirect(true);
    } catch (error) {
      snackbar.error(error);
    }
  }
};
