import {
  Button,
  IconButton,
  makeStyles,
  SvgIcon,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import {
  ButtonBaseClassKey,
  ExtendButtonBaseTypeMap,
} from '@material-ui/core/ButtonBase';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import classNames from 'classnames';
import * as React from 'react';

interface ResponseIconButtonProps extends ButtonProps {
  icon: typeof SvgIcon;
  iconRight?: boolean;

  // https://github.com/mui-org/material-ui/issues/15827
  component?: React.ElementType;
}

type ResponsiveIconButtonTypeMap<
  P = {},
  D extends React.ElementType = 'button'
> = ExtendButtonBaseTypeMap<{
  props: P & ResponseIconButtonProps;
  defaultComponent: D;
  classKey: ButtonBaseClassKey;
}>;

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    // To maintain consistent padding between Button & IconButton forms
    padding: spacing(0.75, 1),
  },

  leftIcon: {
    marginRight: spacing(1),
  },
  rightIcon: {
    marginLeft: spacing(1),
  },
}));

export const ResponsiveIconButton = React.forwardRef<
  HTMLButtonElement,
  ResponseIconButtonProps
>(({ children, icon: Icon, iconRight = false, className, ...props }, ref) => {
  const classes = useStyles();
  const {
    breakpoints: { up },
  } = useTheme();

  const lgUp = useMediaQuery(up('lg'));
  const xsUp = useMediaQuery(up('xs'));

  return !lgUp ? (
    <IconButton
      className={classNames(classes.button, className)}
      {...props}
      // IconButton doesn't support 'large'
      size={props.size && props.size === 'large' ? 'medium' : props.size}
      ref={ref}
    >
      <Icon />

      {/* Fix for upload button with component="label" and input inside children */}
      {!xsUp && children}
    </IconButton>
  ) : (
    <Button
      className={classNames(classes.button, className)}
      ref={ref}
      {...props}
    >
      {!iconRight && <Icon className={classes.leftIcon} />}
      {children}
      {iconRight && <Icon className={classes.rightIcon} />}
    </Button>
  );
}) as OverridableComponent<ResponsiveIconButtonTypeMap>;
